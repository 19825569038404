/******************* Color ********************/
*::-webkit-selection {
  color: #333;
  background: #ccc;
}
*::-moz-selection {
  color: #333;
  background: #ccc;
}
*::selection {
  color: #333;
  background: #ccc;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
p {
  color: #eee;
}

body {
  background-color: #181c27;
}

h2.title-h2 {
  background-color: rgba(60, 73, 91, 0.7333333333);
}

a {
  color: lightblue;
}

blockquote {
  background-color: rgba(60, 73, 91, 0.7333333333);
  border-color: #ccc;
}
blockquote p {
  color: #eee;
}

table th {
  color: #eee;
  background-color: rgba(60, 73, 91, 0.7333333333);
}

.kratos-hentry {
  background-color: rgba(37, 45, 56, 0.8745098039);
}

.kratos-entry-border-new a {
  color: #eee;
}
.kratos-entry-border-new .kratos-post-meta-new {
  border-top: 1px solid #ccc;
  background: rgba(37, 45, 56, 0.8745098039);
}
.kratos-entry-border-new .kratos-status i {
  color: #ccc;
}
.kratos-entry-border-new .kratos-status p a {
  color: lightblue;
}
.kratos-entry-border-new .kratos-status p a:hover {
  color: #51aded;
}

.kratos-entry-header span, .kratos-entry-header a {
  color: #eee;
}

ul.kratos-post-meta {
  color: #ccc;
}
ul.kratos-post-meta span, ul.kratos-post-meta a {
  color: #ccc;
}

.kratos-post-content p, .kratos-post-content table td {
  color: #eee;
}

.kratos-post-inner .kratos-copyright {
  background-color: rgba(60, 73, 91, 0.7333333333);
}
.kratos-post-inner .kratos-copyright h5 {
  color: #eee;
}
.kratos-post-inner .kratos-copyright a {
  color: #eee;
}

.kratos-entry-footer .footer-tag a {
  color: #eee;
  background-color: rgba(37, 45, 56, 0.8745098039);
}
.kratos-entry-footer .pull-date span {
  color: #eee;
}

.post-navigation a {
  color: #eee;
}

#kratos-widget-area .widget a, #kratos-widget-area .widget-title i {
  color: #ccc;
}
#kratos-widget-area .widget.widget-kratos-about .site-meta > .meta-item:hover {
  background-color: rgba(60, 73, 91, 0.7333333333);
}
#kratos-widget-area .widget.widget-kratos-tags .tag-clouds a {
  background-color: rgba(37, 45, 56, 0.8745098039);
}
#kratos-widget-area .widget.widget-kratos-tags .tag-clouds a:hover {
  color: rgba(37, 45, 56, 0.8745098039);
  background-color: #ccc;
}
#kratos-widget-area .widget.widget-kratos-categories .category-list-item::before {
  color: #ccc;
}
#kratos-widget-area .widget.widget-kratos-categories .category-list-item .category-list-count {
  color: #eee;
  background-color: rgba(60, 73, 91, 0.7333333333);
}

.pagination a, .pagination span {
  background-color: rgba(37, 45, 56, 0.8745098039);
  color: #eee;
}
.pagination > li > a {
  background-color: rgba(40, 44, 52, 0.8666666667);
}

.kratos-hentry, .navigation div, #kratos-widget-area .widget {
  background-color: rgba(40, 44, 52, 0.8666666667);
}

.arc-tags a {
  color: #ccc !important;
  background-color: rgba(37, 45, 56, 0.8745098039) !important;
  border-color: #ccc !important;
}
.arc-tags a:hover {
  color: #51aded !important;
}

.linkpage ul li a h4 {
  color: #eee;
}
.linkpage ul li:hover {
  background-color: rgba(60, 73, 91, 0.7333333333);
}

.kratos-post-header-title {
  color: #eee;
}

.xControl {
  background-color: rgba(60, 73, 91, 0.7333333333);
}
.xControl .xHeading span {
  color: #eee;
}

.index-post .kratos-entry-header .kratos-entry-title {
  color: #eee;
}
.index-post .kratos-entry-header .kratos-entry-title::after {
  background-color: #eee;
}

#archives .posts-collapse .post-title a,
.categories > ul.category-list > li.category-list-item a {
  color: #ccc;
}

.post-comments {
  background-color: rgba(37, 45, 56, 0.8745098039);
}

a:hover,
.kratos-entry-border-new a:hover,
.kratos-entry-header .kratos-post-meta a:hover,
.kratos-post-inner .kratos-copyright a:hover,
#footer li a:hover,
#archives .posts-collapse .post-title a:hover {
  color: #51aded;
}

#kratos-desktop-topnav {
  background: rgba(24, 28, 39, 0.8);
}

#kratos-mobile-topnav {
  background: rgba(22, 23, 26, 0.9019607843);
}

.toc-item.active > a {
  color: rgba(37, 45, 56, 0.8745098039);
}
.toc-item.active > a::before {
  border-left-color: rgba(37, 45, 56, 0.8745098039);
}

span.blur {
  text-shadow: 0 0 0.5em #eee;
}
span.blur:hover {
  color: #eee !important;
}

/******************* Plugins ********************/
#dsqjs a {
  color: lightblue;
}
#dsqjs .dsqjs-tab-active {
  color: #eee;
}
#dsqjs-msg {
  color: #eee;
  background-color: rgba(37, 45, 56, 0.8745098039);
}

.post-comments .v[data-class=v] p,
.post-comments .v[data-class=v] .vheader label,
.post-comments .v[data-class=v] .veditor, .post-comments .v[data-class=v] .veditor::placeholder {
  color: #eee;
}
.post-comments .v[data-class=v] .vbtn,
.post-comments .v[data-class=v] .vcount,
.post-comments .v[data-class=v] .vcard .vhead span.vnick,
.post-comments .v[data-class=v] .vcard .vhead .vtime {
  color: #ccc;
}
.post-comments .v[data-class=v] .vicon,
.post-comments .v[data-class=v] .vcard .vhead .vreply svg {
  fill: #ccc;
}
.post-comments .v[data-class=v] .vpanel {
  background-color: rgba(37, 45, 56, 0.8745098039);
}
.post-comments .v[data-class=v] .veditor:focus, .post-comments .v[data-class=v] .vinput:focus {
  background-color: rgba(60, 73, 91, 0.7333333333);
}

.aplayer * {
  color: unset;
}

span.mjx-chtml.MJXc-display {
  color: #eee;
}

/******************* Images ********************/
.widget-kratos-about .photo-background {
  background-image: url("../images/about.webp");
}

.kratos-cover.kratos-cover-2 {
  background-image: url("../images/banner_dark.webp");
}

@media (min-width: 768px) {
  body.custom-background {
    background-image: url("../images/bg_dark.webp");
  }
}
.post-comments.bg-image textarea {
  background: url("../images/comment_dark.webp") right bottom no-repeat !important;
}

.gt-counts {
  color: #ffffff;
}

.gt-header-textarea {
  background-color: rgb(16, 19, 28) !important;
  color: #ffffff !important;
}

.gt-user-name {
  color: #ffffff;
}

.gt-header-preview {
  background-color: rgb(16, 19, 28) !important;
}

.gt-btn-preview {
  background-color: rgb(16, 19, 28) !important;
}

.gt-comment-content {
  background-color: rgb(16, 19, 28) !important;
}

.gt-popup {
  background-color: rgb(16, 19, 28) !important;
  border-color: #6190e8 !important;
}